import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { NewsList } from '../NewsList'

export const NewsListBlock = () => {
  return (
    <div className="news-list-block-container">
      <Row className="mb-5">
        <Col xs={6} sm={8}>
          <div className="title-container">
            <h2 className="title">Latest news</h2>
          </div>
        </Col>
        <Col xs={6} sm={4} className="d-flex justify-content-end">
          <Link className="button outline" to="/resources/news">
            All News
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </Col>
      </Row>
      <Row>
        <NewsList pagination={false} limit={3} />
      </Row>
    </div>
  )
}
